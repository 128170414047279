import {
    Box,
    Button,
    Flex,
    Grid,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Spinner,
    Text,
    VStack,
    useBreakpointValue
} from "@chakra-ui/react";
import {useNavigate, useParams} from "react-router-dom";
import { useContext, useEffect, useState} from "react";
import {ReserveRequest, SeatLightDto, SelectedBulkSeats} from "../../core/types";
import {getCategoryColor} from "../../core/helpers";
import CategoryBox from "./CateogryBox";
import SelectedSeatRow from "./SelectedSeatRow";
import {MainContext} from "../../contexts/MainContext";
import RepDetails from "../RepDetails";
import TheaterHeader from "../TheaterHeader";
import useOrdersRepo from "../../hooks/api/useOrdersRepo";
import {SessionStatuses} from "../../core/enums";
import CustomButton from "../CustomButton";
import React from "react";
import { FaMinus, FaPlus, FaSearchMinus, FaSearchPlus, FaTicketAlt } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ErrorPage from "../ErrorPage";
import VerticalText from "./VerticalText";
import RoomArea from "./RoomArea";

function ChooseSeats() {
    const [transformationFactor] = useState<number>(20);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedSeat, setSelectedSeat] = useState<SeatLightDto | null>(null);
    const [selectOrder, setSelectOrder] = useState(0);
    const [zoomLevel, setZoomLevel] = useState(1);

    let { repId } = useParams();
    const { rep, setRep, loadRep, getPriceName, theater, error} = useContext(MainContext);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const {reserveSeats, cancelSeats} = useOrdersRepo();
    const [ finishLoadingRep, setFinishLoadingRep] = useState<boolean>(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [maxHeight, setMaxHeight] = useState<number>(0);
    const [maxWidth, setMaxWidth] = useState<number>(0);
    const [minHeight, setMinHeight] = useState<number>(0);
    const [defHeight, setDefHeight] = useState<number>(0);
    const [minWidth, setMinWidth] = useState<number>(0);
    const { t } = useTranslation();
    const [selectedBulkSeats, setSelectedBulkSeats] = useState<SelectedBulkSeats[]>([]);
    const [zeroPrice, setZeroPrice] = useState<boolean>(false);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        const fetchRep = async () => {
            if (repId === undefined) {
                navigate("/");
            }
            else {
                if (rep?.id !== repId && repId) {
                    await loadRep(repId);
                    setFinishLoadingRep(true);
                } else {
                    await cancelSeats();
                    sessionStorage.removeItem("sessionId");
                    setHeight();
                    setWidth();
                }
                setLoading(false);
            }
        };
        fetchRep().catch(null);
    }, [])

    useEffect(()=>{
        setWidth();
        setHeight(false);
    },[transformationFactor])

    useEffect(() => {
        if (!finishLoadingRep) return;
        const processRep = async () => {
            setHeight();
            setWidth();
            const dto = await cancelSeats();
            const sessionId = sessionStorage["sessionId"];
            sessionStorage.removeItem("sessionId");

            if (sessionId) {
                if (dto.status !== SessionStatuses.Error) {
                    const updatedSeats = rep.seats.map(s => {
                        const ss = dto.seats.find(seat => seat.id === s.id);
                        if (ss) {
                            return {
                                ...s,
                                isSelected: true,
                                isAvailable: true,
                                selectedCategory: ss.category
                            }
                        }
                        return s;
                    });
                    setRep(prevRep => ({...prevRep, seats: updatedSeats}));
                    /*
                    dto.seats.filter(seat => seat.isBulk).forEach(seat => {
                        const bulkSeat = selectedBulkSeats.find(s => s.category === seat.category);
                        if (bulkSeat) {
                            let s = selectedBulkSeats.find(s => s.category === seat.category);
                            if (s) s.selectedSeats++;
                        }
                    });
                    setSelectedBulkSeats([...selectedBulkSeats]);
                    */
                }
            }
        }
        if (rep) {
            processRep().catch(null);
        }
    }, [finishLoadingRep]);
    useEffect(() => {
        if (theater.isOnlineSellingEnabled === undefined) return;
        if (!theater.isOnlineSellingEnabled)
        {
            navigate("/stop");
            return;
        }
    },[theater]);
    /* eslint-enable react-hooks/exhaustive-deps */

    useEffect(() => {
        setSelectedBulkSeats(rep.bulkSeats.map((cat) => { return { category: cat.category, selectedSeats: 0, categoryName: cat.categoryName, noOfSeats: cat.noOfSeats, categoryPrice: cat.categoryPrice } as SelectedBulkSeats}));
    },[rep.bulkSeats]);


    const setHeight = (changeDefault?: boolean ) => {
        let seatWithGreatestY = rep.seats.length > 0 ? rep.seats.reduce((max, seat) => max.y > seat.y ? max : seat): {y:0, h:0};
        let objWithGreatestY = rep.objects.length > 0 ? rep.objects.reduce((max, obj) => max.y + max.h > obj.y + obj.h ? max : obj): {y:0, h:0};

        let seatMinY =  rep.seats.length > 0 ? rep.seats.reduce((min, seat) => min.y < seat.y ? min : seat): {y:0};
        let objMinY =  rep.objects.length > 0 ? rep.objects.reduce((min, obj) => min.y < obj.y ? min : obj): {y:0};

        setMinHeight(Math.min(seatMinY.y, objMinY.y)*transformationFactor);
        let h = (Math.max(seatWithGreatestY.y + 1, objWithGreatestY.y + objWithGreatestY.h)) * transformationFactor;
        setMaxHeight(h);
        if (changeDefault === undefined) changeDefault = true;
        if (changeDefault && defHeight === 0) {
            setDefHeight(h);
        }
    };

    
    const setWidth = () => {
        let seatWithGreatestX =  rep.seats.length > 0 ? rep.seats.reduce((max, seat) => max.x > seat.x ? max : seat): {x:0};
        let objWithGreatestX = rep.objects.length > 0 ? rep.objects.reduce((max, obj) => max.x > obj.x + obj.w ? max : obj) : {x:0, w:0};
        let seatMinX =  rep.seats.length > 0 ? rep.seats.reduce((min, seat) => min.x < seat.x ? min : seat): {x:0};
        let objMinX =  rep.objects.length > 0 ? rep.objects.reduce((min, obj) => min.x < obj.x ? min : obj): {x:0};

        setMaxWidth((Math.max(seatWithGreatestX.x + 1, objWithGreatestX.x + objWithGreatestX.w))* transformationFactor);
        setMinWidth(Math.min(seatMinX.x, objMinX.x)*transformationFactor);
    };

    const toggleSeatSelection = (index: number) => {
        if (!rep.seats[index].isSelected && !canSlectSeat()) return;

        setSelectOrder(selectOrder + 1);
        const newSeats = [...rep.seats];
        const seat = newSeats[index];
        if (!seat.isAvailable) return;

        if (seat.isSelected){
            seat.isSelected = !seat.isSelected;
        } else {
            seat.selectOrder = selectOrder;
            if (seat.allowedCategories > 0) {
                setIsModalOpen(true);
                setSelectedSeat(seat);
            } else {
                seat.selectedCategory = seat.defaultCategory;
                seat.isSelected = !seat.isSelected;
                setSelectedSeat(null);
            }
        }

        setRep({ ...rep, seats: newSeats });
    };

    const canSlectSeat = (): boolean =>  {
        let seats = rep.seats.filter(s=> s.isSelected).length;
        seats += selectedBulkSeats.reduce((a, s) => a + s.selectedSeats, 0);

        if (seats === theater.maxSeatsPerOrder)
        {
            toast.error(t("SEATS_NO_MORE"));
            return false;
        }

        return true;
    }


    /*
    const textFit = (height: number, width: number, textLength: number, isVerticalText: boolean) => {
        const size = isVerticalText ? height : width;
        const fontSize = (size / textLength) * 0.8;
        return Math.max(Math.min(fontSize, 20), 6);
    };
    */

    const deselectSeat = (seatId: string) => {
        const index = rep.seats.findIndex(seat => seat.id === seatId);
        toggleSeatSelection(index);
    }

    const handleNext = async () => {
        const response = await reserveSeats({
            representationId: rep.id,
            seats: rep.seats.filter(s => s.isSelected).map((s) => ({ id: s.id, category: s.selectedCategory })),
            bulkSeats: selectedBulkSeats.filter(s => s.selectedSeats > 0).map((s) => ({ category: s.category, noOfSeats: s.selectedSeats }))
        } as ReserveRequest);
        if (response.status === SessionStatuses.Created)
        {
            sessionStorage["sessionId"] = response.uniqueId;
            navigate(`/fill-data`);
        }
        else
        {
            if (response.message === "SEATS_MUST_COST")
                setZeroPrice(true);
            else
                setZeroPrice(false);
            setIsSuccessModalOpen(true);
        }
    }



    const layout = useBreakpointValue({ base: "mobile", md: "desktop" });
    
    const addBulkSeat = (cat: SelectedBulkSeats) => {
        if (!canSlectSeat()) return;
        if (cat.selectedSeats >= rep.freeSeats) return;
        setSelectedBulkSeats((prevSeats) => {
            const seatIndex = prevSeats.findIndex(seat => seat.category === cat.category);
                const updatedSeats = [...prevSeats];
                updatedSeats[seatIndex].selectedSeats += 1;
                return updatedSeats;
        });
    };

    const substractBulkSeat = (cat: SelectedBulkSeats) => {
        setSelectedBulkSeats((prevSeats) => {
            const seatIndex = prevSeats.findIndex(seat => seat.category === cat.category);
            const updatedSeats = [...prevSeats];
            if (updatedSeats[seatIndex].selectedSeats > 0) updatedSeats[seatIndex].selectedSeats -= 1;
            return updatedSeats;
        });
    };

    const allSelectedSeats = rep.seats.filter(seat => seat.isSelected).length + selectedBulkSeats.reduce((a, s) => a + s.selectedSeats, 0);


    return error ? (
            <ErrorPage />
        ):(
        layout === "mobile" ? 
        (
            <Flex flexDirection="column" maxWidth="1240px" mx="auto">
                {loading ? (
                    <Flex justifyContent="center" alignItems="center">
                        <Spinner size="xl" thickness="4px" color="blue.500" />
                    </Flex>
                ) : (
                    rep.id ?
                    <React.Fragment>
                        <TheaterHeader theater={theater}/>
                        <Box background={'white'}>
                            <Box>
                                <RepDetails rep={rep} currentPoint={1} isMobile={true}/>
                            </Box>
                            <Flex justifyContent={"center"}>
                                    {selectedBulkSeats.length > 0 &&
                                        <VStack>
                                            <Box fontSize={"1.5em"}>
                                                    {t("SEATS_FREE")}: {rep.freeSeats}
                                            </Box>
                                            {selectedBulkSeats.map((cat, index) => (
                                                <VStack key={index} mb={4}>
                                                    <Box fontSize={"1.5em"}>{cat.categoryName} - {cat.categoryPrice} LEI  </Box>
                                                    <Box >
                                                        <HStack>
                                                            <Button bgColor="#f29e0d" size='sm' onClick={() => substractBulkSeat(cat)}><FaMinus/></Button>
                                                            <Box textAlign={"right"} mx={4} fontSize={"1.5em"}>{cat.selectedSeats}</Box>
                                                            <Button bgColor="#f29e0d" size='sm' onClick={() => addBulkSeat(cat)}><FaPlus/></Button>
                                                        </HStack>
                                                    </Box>
                                                </VStack>
                                            ))}
                                        </VStack>
                                    }                                    
                            </Flex>
                            <Flex flex="1" minHeight={maxHeight + 80} mt="5">
                                <Flex minHeight={maxHeight} minW={'100vw'} overflowX={'scroll'} justifyContent={"center"}>
                                    <Box position="relative" width={maxWidth-minWidth}
                                        style={{
                                            transform: `scale(${zoomLevel})`,
                                            transition: 'transform 0.5s ease-out'
                                        }}>
                                        {rep.seats.map((seat, index) => (
                                            <Box zIndex={1000}
                                                key={index}
                                                position="absolute"
                                                left={`${seat.x * transformationFactor - minWidth}px`}
                                                top={`${seat.y * transformationFactor - minHeight}px`}
                                                width={`${transformationFactor}px`}
                                                height={`${transformationFactor}px`}
                                                padding="2px"
                                            >
                                                <Box
                                                    width="100%"
                                                    height="100%"
                                                    backgroundColor={seat.isAvailable ? (seat.isSelected ? 'orange' : getCategoryColor(seat.defaultCategory)) : 'gray.300'}
                                                    borderRadius="50%"
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    color={seat.isSelected ? 'black' : 'white'}
                                                    fontSize={10}
                                                    onClick={() => seat.isAvailable && toggleSeatSelection(index)}
                                                    _hover={seat.isAvailable ? {border: '2px solid black'} : {}}
                                                    cursor={seat.isAvailable ? "pointer" : "default"}
                                                >
                                                    {false && seat.number}
                                                </Box>
                                            </Box>
                                        ))}

                                        {rep.objects.map((obj, index) => {
                                            return (
                                                <Box p={'1px'} key={index} zIndex={obj.zIndex}>
                                                <Box
                                                    zIndex={obj.zIndex}
                                                    position="absolute"
                                                    left={`${obj.x * transformationFactor  - minWidth}px`}
                                                    top={`${obj.y * transformationFactor - minHeight}px`}
                                                    width={`${obj.w * transformationFactor-2}px`}
                                                    height={`${obj.h * transformationFactor-2}px`}
                                                    backgroundColor={obj.backColor}
                                                    backgroundImage={obj.backPicture + "/o"}
                                                    backgroundSize={"100%"}
                                                    display="flex"
                                                    alignItems="center"
                                                    justifyContent="center"
                                                    overflow={'hidden'}
                                                >
                                                    {obj.isVerticalText && <VerticalText  gridSize={transformationFactor} text={obj.text}></VerticalText>}
                                                    {!obj.isVerticalText && <Box fontSize={transformationFactor/2}>{obj.text}</Box>}
                                                </Box>
                                                </Box>
                                            );
                                        })}
                                    </Box>
                                </Flex>
                            </Flex>
                        </Box>
                        <Flex
                            position="fixed"
                            bottom={0}
                            width="100%"
                            justifyContent="space-between"
                            bg="white"
                            boxShadow="md"
                            zIndex="sticky"
                            >
                            <Box flexBasis="50%" bgColor={'#f58500'} p={1}>
                                <Grid templateColumns="100%" color="white">
                                    <Text textAlign={"center"}> 
                                        {rep.seats.filter(seat => seat.isSelected).reduce((a, s) => a + getPriceName(s.selectedCategory).price, 0) + 
                                        selectedBulkSeats.reduce((a,s) => a+= s.categoryPrice*s.selectedSeats, 0)} LEI                             
                                    </Text>
                                    <Flex alignItems={'center'} justifyContent={"center"}> <FaTicketAlt size={'25'}/> <Text ml={2}>{allSelectedSeats}</Text>  </Flex>
                                </Grid>
                            </Box>
                            <Box flexBasis="50%">
                                <CustomButton date={t("SEATS_NEXT_UP")} hour={t("SEATS_NEXT_DOWN")} soldOut={allSelectedSeats === 0} onClick={handleNext} height={60}/>
                            </Box>
                        </Flex>
                    </React.Fragment>
                    : <Flex>
                    <Box>{t("MAINTENANCE_TITLE")}</Box><br/>
                    <CustomButton date={t("SEATS_SHOW_UP")} hour={t("SEATS_SHOW_DOWN")} soldOut={false} onClick={()=> {navigate("/");}} height={60}/>
                    </Flex>
                    )}
                <Modal isOpen={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false);
                        }}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{t("SEATS_SEL_CAT")}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            {selectedSeat && rep.seatCategories.filter(category => selectedSeat.allowedCategories & category.category).map((category, index) => (
                                <div key={index} onClick={() => {
                                    selectedSeat.selectedCategory = category.category;
                                    selectedSeat.isSelected = !selectedSeat.isSelected;
                                    setIsModalOpen(false);
                                }}>
                                    <CategoryBox category={category} isChanging={true} />
                                </div>

                            ))}
                        </ModalBody>

                    </ModalContent>
                </Modal>
                <Modal isOpen={isSuccessModalOpen} onClose={() => setIsSuccessModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{t("SEATS_TAKEN_TITLE")}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Flex justifyContent={'center'}><Text>{t("SEATS_TAKEN_TEXT")}</Text></Flex>
                            <Flex justifyContent={'center'}><Button colorScheme="blue" onClick={() => setIsSuccessModalOpen(false)}>{t("SEATS_TAKEN_BUTTON")}</Button></Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Flex>
        ):
        (
            <Flex flexDirection="column"  maxWidth="1240px" mx="auto" p={4}>    
                {loading ? (
                    <Flex justifyContent="center" alignItems="center">
                        <Spinner size="xl" thickness="4px" color="blue.500" />
                    </Flex>
                ) : (
                    rep.id ?
                    <React.Fragment>
                        <TheaterHeader theater={theater}/>
                        <Box boxShadow={'sm'} background={'white'} borderRadius={7} pb={4}>
                            <RepDetails rep={rep} currentPoint={1}/>
                            <Flex mt={4} css={`   
                                user-select: none;
                                -webkit-user-select: none; 
                                -moz-user-select: none;    
                                -ms-user-select: none;`}>
                                
                                <Box flex={4} overflow={'auto'} transition="height 1s ease">
                                    <VStack>                                                                                    
                                        <Box fontSize={"1.5em"} mt={"30px"}>
                                            {t("SEATS_FREE")}: {rep.freeSeats}
                                        </Box>
                                        {selectedBulkSeats.length > 0 &&
                                            <Box>
                                                {selectedBulkSeats.map((cat, index) => (
                                                    <Flex key={index} mb={4} gap={8}>
                                                        <Box flex={5} fontSize={"1.5em"}>{cat.categoryName} - {cat.categoryPrice} LEI</Box>
                                                        <Box flex={1}>
                                                            <HStack>
                                                                <Button bgColor="#f29e0d" size='sm' onClick={() => substractBulkSeat(cat)}><FaMinus/></Button>
                                                                <Box textAlign={"right"} mx={4} fontSize={"1.5em"}>{cat.selectedSeats}</Box>
                                                                <Button bgColor="#f29e0d" size='sm' onClick={() => addBulkSeat(cat)}><FaPlus /></Button>
                                                            </HStack>
                                                        </Box>
                                                    </Flex>
                                                ))}
                                            </Box>
                                        }
                                        {rep.seats.length > 0 && 
                                        <RoomArea rep={rep} zoomLevel={zoomLevel} toggleSeatSelection={toggleSeatSelection}/>}
                                        <Flex pb={8} fontSize={"1.5em"} justifyContent={"end"}>{t("SEATS_TOTAL_MONEY")}: {selectedBulkSeats.reduce((a, i) => a += i.categoryPrice * i.selectedSeats, 0)} LEI</Flex>
                                    </VStack>
                                </Box>
                                {rep.seats.length > 0 &&
                                <Box flex={1} p={4}>
                                    <Box mt={4}>
                                        <Flex mb={4} justifyContent={"left"}>
                                            <Button bgColor="#f29e0d" size='sm' onClick={() => {
                                                if (transformationFactor > 17) {
                                                    //setTransformationFactor(transformationFactor - 6);
                                                    if (zoomLevel <= 1) return;
                                                    setZoomLevel(zoomLevel - 0.25); // Adjust zoom level
                                                }
                                            }}><FaSearchMinus /></Button>
                                            <Button bgColor="#f29e0d" size='sm' ml={8} onClick={() => {
                                                if (transformationFactor < 41) {
                                                    //setTransformationFactor(transformationFactor + 6);
                                                    if (zoomLevel >= 4) return;
                                                    setZoomLevel(zoomLevel + 0.25); // Adjust zoom level
                                                }
                                            }}><FaSearchPlus /></Button>
                                        </Flex>
                                        <Flex alignItems="center">
                                            <Box
                                                width="15px"
                                                height="15px"
                                                backgroundColor={"#e5e5e5"}
                                                marginRight={2}
                                                borderRadius="50%"
                                            />
                                            <Text fontSize={'md'} fontFamily={'Roboto Condensed'}>{t("SEATS_UNAVAILABLE_SEAT")}</Text>
                                        </Flex>
                                        <Flex alignItems="center">
                                            <Box
                                                width="15px"
                                                height="15px"
                                                backgroundColor={"orange"}
                                                marginRight={2}
                                                borderRadius="50%"
                                            />
                                            <Text fontFamily={'Roboto Condensed'} fontSize={'md'}>{t("SEATS_SELECTED_SEAT")}</Text>
                                        </Flex>
                                        <Flex>
                                            <Box backgroundColor={'#777'} h={'1px'} w={'100%'} my={2}></Box>
                                        </Flex>
                                        {rep.seatCategories.sort((a,b) => b.price - a.price).map((category, index) => (
                                            <CategoryBox key={index} category={category}  isChanging={false}/>
                                        ))}
                                    </Box>
                                    <Flex direction="column" mt={10}>
                                        {rep.seats.filter(seat => seat.isSelected)
                                            .sort((a, b) => a.selectOrder - b.selectOrder)
                                            .map((seat, index) => (
                                            <SelectedSeatRow
                                                key={index}
                                                seat={seat}
                                                deselectSeat={deselectSeat}
                                                animate={true}
                                                getPriceName={getPriceName}
                                            />
                                        ))}
                                    </Flex>
                                    <Flex mt={4} justifyContent={"center"}>
                                        <CustomButton date={t("SEATS_NEXT_UP")} hour={t("SEATS_NEXT_DOWN")} soldOut={allSelectedSeats === 0} onClick={handleNext} height={60}/>
                                    </Flex>
                                    <Flex mt={4} justifyContent={"center"}>
                                        <CustomButton date={t("SEATS_SHOW_UP")} hour={t("SEATS_SHOW_DOWN")} soldOut={false} onClick={()=> {navigate("/");}} height={60}/>
                                    </Flex>
                                    </Box>}
                            </Flex>
                            {rep.seats.length === 0 &&
                                <Flex justifyContent={"center"} gap={8} mb={16} mt={"30px"}>
                                    <CustomButton date={t("SEATS_SHOW_UP")} hour={t("SEATS_SHOW_DOWN")} soldOut={false} onClick={()=> {navigate("/");}} height={60}/>
                                    <CustomButton date={t("SEATS_NEXT_UP")} hour={t("SEATS_NEXT_DOWN")} soldOut={allSelectedSeats === 0} onClick={handleNext} height={60}/>
                                </Flex>
                            }
                        </Box>
                    </React.Fragment>
                    : <Flex>
                            <Box>{t("MAINTENANCE_TITLE")}</Box><br/>
                            <CustomButton date={t("SEATS_SHOW_UP")} hour={t("SEATS_SHOW_DOWN")} soldOut={false} onClick={()=> {navigate("/");}} height={60}/>
                        </Flex>
                )}
                <Modal isOpen={isModalOpen}
                    onClose={() => {
                        setIsModalOpen(false);
                        }}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{t("SEATS_SEL_CAT")}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <VStack pb={10}>
                                {selectedSeat && rep.seatCategories.filter(category => selectedSeat.allowedCategories & category.category).map((category, index) => (
                                    <Flex width={'200px'}
                                        justifyContent={"center"}
                                        alignItems={"center"}
                                        height={'40px'}
                                        key={index} 
                                        boxShadow={'lg'} 
                                        cursor='pointer'
                                        mt={4} 
                                        onClick={() => {
                                            selectedSeat.selectedCategory = category.category;
                                            selectedSeat.isSelected = !selectedSeat.isSelected;
                                            setIsModalOpen(false);
                                        }}>
                                        <CategoryBox category={category} isChanging={true}/>
                                    </Flex>

                                ))}
                            </VStack>
                        </ModalBody>

                    </ModalContent>
                </Modal>
                <Modal isOpen={isSuccessModalOpen} onClose={() => setIsSuccessModalOpen(false)}>
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>{zeroPrice ? t("SEATS_MUST_COST_TITLE") : t("SEATS_TAKEN_TITLE")}</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody>
                            <Flex justifyContent={'center'}><Text>{zeroPrice ? t("SEATS_MUST_COST_TEXT") : t("SEATS_TAKEN_TEXT")}</Text></Flex>
                            <Flex justifyContent={'center'} mt={10}><Button colorScheme="blue" onClick={() => setIsSuccessModalOpen(false)}>{t("SEATS_TAKEN_BUTTON")}</Button></Flex>
                        </ModalBody>
                    </ModalContent>
                </Modal>
            </Flex>
        ));
}

export default ChooseSeats;